import React from "react";
import photoProfil from "../assets/img/photo-profil.png";

const HeroHeader = () => {
  return (
    <div id="hero-header">
      <div className="hero-header">
        <div className="text-header">
          <h1>
            Hello, je suis Célia,{" "}
            <span>développeuse Full-Stack (React - Symfony)</span>, bienvenue
            sur mon <span>portfolio de création web</span>. 🌈
          </h1>
          <div className="btn-home">
            <a href="#realisations">
              <button className="btn-red">Découvrez mes réalisations.</button>
            </a>
            <a href="#competences">
              <button className="btn-pink">Découvrez mes compétences.</button>
            </a>
          </div>
        </div>
        <img src={photoProfil} alt="Célia" className="hero-photo" />
      </div>
      <div className="banniere-more">
        <h1 className="h3-desktop">Créative | Autonome | Polyvalente</h1>
        <h1 className="h3-mobile">
          Créative <br /> Autonome <br /> Polyvalente
        </h1>
        <a href="#a-propos">
          <button className="btn-red">En savoir plus sur moi.</button>
        </a>
      </div>
    </div>
  );
};

export default HeroHeader;
